<template>
  <BaseModal :is-open="isOpen" :fullscreen="true" @close="close">
    <template #header>
      <div class="px-1 py-1 flex justify-end">
        <BaseButton theme="white" size="sm" class="w-8 !p-0" @click="close">
          <BaseIcon name="outline_x" />
        </BaseButton>
      </div>
    </template>
    <template #body>
      <div
        class="p-3 pb-8 sm:p-10 sm:pb-10 pt-0 sm:pt-0 flex flex-col items-center text-center space-y-4"
      >
        <BaseIcon
          name="logo"
          size="none"
          title="Communication Platform"
          class="h-12 w-24 lg:w-32 text-primary"
        />
        <div class="px-12 space-y-3">
          <div class="text-black font-medium">
            {{ $t('login.title') }}
          </div>
          <i18n-t
            keypath="login.description"
            tag="div"
            class="text-neutral-500 text-sm"
          >
            <template #agreement>
              <BaseLink
                href="https://wnhub.io/terms-conditions"
                target="_blank"
              >
                {{ $t('login.description_agreement') }}
              </BaseLink>
            </template>
            <template #privacy>
              <BaseLink href="https://wnhub.io/privacy-policy" target="_blank">
                {{ $t('login.description_privacy') }}
              </BaseLink>
            </template>
          </i18n-t>
        </div>
        <template v-if="isGoogleAuthAllowed">
          <BaseButton
            theme="secondary"
            look="border"
            class="w-full h-12 px-2 text-sm font-medium relative"
            size="none"
            @click="authStore.requestGoogleCode"
          >
            <BaseIcon
              class="mr-6 h-6 w-6 absolute left-3"
              size="none"
              name="brand_google_color"
            />
            <div>
              {{ $t('login.sign_in_google') }}
            </div>
          </BaseButton>
          <!-- <BaseButton
          theme="secondary"
          look="border"
          class="w-full h-12 px-2 text-sm font-medium relative"
          size="none"
          >
          <BaseIcon
            class="mr-6 h-6 w-6 absolute left-3"
            size="none"
            name="brand_facebook_circle_color"
          />
          <div>
            {{ $t('login.sign_in_facebook') }}
          </div>
          </BaseButton> -->
          <div class="flex justify-center items-center text-sm w-full px-8">
            <div class="w-full border-t border-gray-200"></div>
            <span
              class="px-2 py-0.5 text-gray uppercase text-sm whitespace-nowrap"
            >
              {{ $t('login.or') }}
            </span>
            <div class="w-full border-t border-gray-200"></div>
          </div>
        </template>
        <BaseForm
          v-show="isEnterCode"
          ref="formCodeComponent"
          class="w-full space-y-4"
          :on-error="onCodeSubmitError"
          :method="submitCodeForm"
        >
          <BaseInput
            ref="codeInput"
            v-model="code"
            :label="$t('login.code_input_label')"
            placeholder="1234"
            input-background-class="bg-gray-50"
            class="w-full text-left"
            name="code"
            rules="required|integer|max:4"
            autocomplete="off"
          />

          <div class="flex gap-2 w-full">
            <BaseButton
              theme="primary"
              look="border"
              class="w-full"
              @click="isEnterCode = false"
            >
              {{ $t('login.change_email') }}
            </BaseButton>
            <BaseButton
              theme="primary"
              look="solid"
              class="w-full"
              type="submit"
              :loading="isCodeLoading"
            >
              {{ $t('login.login') }}
            </BaseButton>
          </div>
        </BaseForm>
        <BaseForm
          v-show="!isEnterCode"
          ref="formComponent"
          class="w-full space-y-4"
          :on-error="onSubmitError"
          :reset-on-submit="false"
          :method="submitForm"
        >
          <BaseInput
            v-model="form.email"
            type="email"
            clear-input
            :placeholder="$t('common.email')"
            input-background-class="bg-gray-50"
            class="w-full text-left"
            name="email"
            rules="email|required"
            autocomplete="email"
          />

          <BaseInput
            v-if="isLoginWithPassword"
            v-model="form.password"
            type="password"
            input-background-class="bg-gray-50"
            class="w-full text-left"
            name="password"
            rules="required|min:5"
          />

          <div v-if="!isLoginWithPassword" class="flex gap-2 w-full">
            <BaseButton
              theme="primary"
              look="border"
              class="w-full"
              @click="isLoginWithPassword = true"
            >
              {{ $t('login.type_password') }}
            </BaseButton>
            <BaseButton
              theme="primary"
              look="solid"
              class="w-full"
              type="submit"
              :loading="isLoading"
            >
              {{ $t('login.send_magic_link') }}
            </BaseButton>
          </div>
          <div v-else class="flex gap-2 w-full">
            <BaseButton
              theme="primary"
              look="border"
              class="w-full"
              @click="isLoginWithPassword = false"
            >
              {{ $t('login.send_magic_link') }}
            </BaseButton>
            <BaseButton
              theme="primary"
              look="solid"
              class="w-full"
              type="submit"
              :loading="isLoading"
            >
              {{ $t('login.login') }}
            </BaseButton>
          </div>
        </BaseForm>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useAuth } from '~/stores/auth'
import BaseForm from '~/components/base/BaseForm.vue'
import BaseInput from '~/components/base/controls/BaseInput.vue'
import { useNotifications } from '~/stores/notifications'

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
})

const { t } = useI18n()
const emit = defineEmits(['close'])
const authStore = useAuth()
const isLoginWithPassword = ref(false)
const isLoading = ref(false)
const isCodeLoading = ref(false)
const isEnterCode = ref(false)
const code = ref('')
const formComponent = ref<InstanceType<typeof BaseForm> | null>(null)
const { pushNotification } = useNotifications()
const { isApp } = useDetect()
const router = useRouter()

const {
  $linkedin: { lintrk },
  $facebook: { fbq },
} = useNuxtApp()

const form = reactive({
  email: '',
  password: '',
})

const { isWebview, isIOS } = useDevice()

// Google Auth on Android in Webview looks fine but on iOS it doesn't work (tested on open wnhub.io link in linkedin app)
const isGoogleAuthAllowed = !isApp && !(isWebview && isIOS)

watch(
  () => props.isOpen,
  (isOpen) => {
    if (isOpen) {
      lintrk('track', { conversion_id: 17206298 })
    }
  }
)

const close = () => {
  emit('close')
}

const closeForm = () => {
  formComponent.value?.resetForm()
  isEnterCode.value = false
  isLoginWithPassword.value = false
  isLoading.value = false
  code.value = ''
  emit('close')
}

const submitForm = async () => {
  isLoading.value = true
  try {
    if (isLoginWithPassword.value) {
      await loginWithPassword()
    } else {
      await sendMagicLink()
    }
    if (!isLoginWithPassword.value) {
      isEnterCode.value = true
    } else {
      closeForm()
    }
  } finally {
    isLoading.value = false
  }
}

const submitCodeForm = async () => {
  isCodeLoading.value = true
  try {
    await loginWithCode()

    closeForm()
  } finally {
    isCodeLoading.value = false
  }
}

const onCodeSubmitError = (error: any) => {
  pushNotification({
    title: t('notifications.login.code_error'),
    theme: 'destructive',
  })
  useLogError(error)
}

const onSubmitError = (error: any) => {
  pushNotification({
    title: t(
      // eslint-disable-next-line prettier/prettier
      `notifications.login.${isLoginWithPassword.value ? 'password' : 'magic'}_error`
    ),
    theme: 'destructive',
  })
  useLogError(error)
}

function trackLogin() {
  lintrk('track', { conversion_id: 17206298 })
  fbq('track', 'CompleteRegistration')
}

const checkProfileComplete = () => {
  if (authStore.auth && !authStore.isProfileCompleted && process.client) {
    navigateTo(
      useLocaleRoute()({
        path: '/users/me/edit/basic',
        query: {
          redirect: router.currentRoute.value.query.redirect
            ? encodeURI(router.currentRoute.value.query.redirect as string)
            : encodeURI(router.currentRoute.value.fullPath),
        },
      })
    )
  }
}

const afterLoginHandler = async () => {
  await authStore.fetchProfile()
  await authStore.updateProfileTimezone()
  trackLogin()
  checkProfileComplete()
}

const loginWithCode = async () => {
  await authStore.authWithMagicCode({ code: code.value, email: form.email })
  await afterLoginHandler()
}

const loginWithPassword = async () => {
  await authStore.authWithPassword(form.email, form.password)
  await afterLoginHandler()
}

const sendMagicLink = async () => {
  const redirect = location.pathname + location.search
  await authStore.requestMagic(form.email, redirect)
  !isApp && pushNotification({ title: t('notifications.login.magic_success') })
}
</script>
