import { markRaw } from 'vue'
import {
  DEFAULT_COLOR_GRAY,
  DEFAULT_COLOR_WHITE,
  DEFAULT_IMAGE_VERTICAL,
  DEFAULT_LONG_MARKDOWN_TEXT,
  DEFAULT_LONG_TEXT,
} from '../constants'
import View from './StatisticsView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

const DEFAULT_STAT_CARD = {
  title: '500K',
  subtitle: 'candidates in our talent pool',
}

export default {
  viewComponent: markRaw(View),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    text:
      DEFAULT_LONG_MARKDOWN_TEXT +
      '\n\n' +
      DEFAULT_LONG_TEXT +
      '\n\n' +
      DEFAULT_LONG_TEXT,
    image: DEFAULT_IMAGE_VERTICAL,
    imagePosition: 'right',
    imageSize: 'w-1/3',
    textColor: DEFAULT_COLOR_GRAY,
    bgColor: DEFAULT_COLOR_WHITE,
    cardTextColor: '#5818f4',
    cardBgColor: '#eee8fe',
    colsNumberOnDesktop: 4,
    colsNumberOnMobile: 1,
    statCards: [
      DEFAULT_STAT_CARD,
      DEFAULT_STAT_CARD,
      DEFAULT_STAT_CARD,
      DEFAULT_STAT_CARD,
    ],
  },
}
