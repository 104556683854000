import { useI18n } from 'vue-i18n'
import type { Media } from '~/models/media'

export const useLocalMedia = () => {
  const { locale } = useI18n()

  const getLocalMedia = (media?: Media | Media[] | null) => {
    if (!media) {
      return null
    }

    if (Array.isArray(media)) {
      if (!media.length) {
        return null
      } else if (locale.value === 'en') {
        return media.find((item) => !item.locale)
      } else {
        return (
          media.find((item) => item.locale === locale.value) ||
          media.find((item) => !item.locale)
        )
      }
    } else {
      return media
    }
  }

  return { getLocalMedia }
}
