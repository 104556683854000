<template>
  <div class="rounded-xl flex flex-col gap-1 cursor-pointer">
    <BaseRatio :ratio="1" class="mb-4">
      <img :src="speaker.image" class="w-full h-full object-cover rounded-xl" />
    </BaseRatio>
    <div>
      <div
        class="font-bold text-gray-900 break-words"
        :style="{ color: titleColor }"
      >
        {{ speaker.title }}
      </div>
      <div
        class="marked text-gray-400 break-words"
        :style="{ color: subtitleColor }"
        v-html="getMarkedTextWithBlankLinks(speaker.subtitle, linkColor)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Speaker } from './types'

interface PropsInterface {
  speaker: Speaker
  titleColor?: string
  subtitleColor?: string
  linkColor?: string
}

defineProps<PropsInterface>()

const { getMarkedTextWithBlankLinks } = useMarkedText()
</script>
