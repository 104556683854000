<template>
  <div
    :id="uuid"
    ref="expoBlock"
    class="bg-white relative overflow-hidden p-0 max-h-[70vh] md:max-h-[80vh]"
    :class="[rounded]"
    :style="{
      height: height,
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
    }"
  >
    <div
      v-if="isLoading"
      class="absolute inset-0 flex items-center justify-center"
    >
      <BaseSpinner />
    </div>
    <div v-else>
      <div id="map" ref="map" class="map-container"></div>
      <div
        class="gui absolute pointer-events-auto z-20 flex flex-col items-center transition-all duration-700 ease-in-out right-0 top-4"
        :class="topInFrame"
        :style="{
          'margin-top': mtHubChatButton,
        }"
      >
        <button ref="guiPlus" class="gui-plus mr-2 px-2 mb-2.5">
          <BaseIcon
            name="outline_expo_plus_circle"
            size="none"
            class="w-[40px]"
          />
        </button>
        <button ref="guiMinus" class="gui-minus mr-2 px-2 mb-2.5">
          <BaseIcon
            name="outline_expo_minus_circle"
            size="none"
            class="w-[40px]"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePageEditor } from '~/stores/page-editor'

const pageEditorStore = usePageEditor()
const props = defineProps({
  uuid: {
    type: String,
    required: true,
  },
  formData: {
    type: Object,
    default: () => ({}),
  },
  isInIframe: {
    type: Boolean,
    default: false,
  },
})

const loading = ref(true)
const offsetX = ref(0)
const offsetY = ref(0)
const startX = ref(0)
const startY = ref(0)
const dY = ref(0)
const isDragging = ref(false)
const isZoomByTouch = ref(false)
const scale = ref(1)
const minScale = ref(1)
const maxScale = ref(2)
const dScale = ref(0.35)
const dScaleZooming = ref(0.001)
const expoBlock = ref<HTMLDivElement | undefined>(undefined)
const map = ref<HTMLDivElement | undefined>(undefined)
const guiPlus = ref<HTMLButtonElement | undefined>(undefined)
const guiMinus = ref<HTMLButtonElement | undefined>(undefined)
const resizeFunction = ref<((event: any) => void) | undefined>(undefined)

onBeforeUnmount(() => {
  resizeFunction.value &&
    window.removeEventListener('resize', resizeFunction.value)
})

const isLoading = computed(() => loading.value)

const height = computed(() => {
  if (props.isInIframe) {
    return '100vh'
  }
  return props.formData.blockHeight ? props.formData.blockHeight + 'px' : 'auto'
})

const rounded = computed(() => {
  return props.isInIframe ? '' : 'rounded-xl'
})

const topInFrame = computed(() => {
  return props.isInIframe ? 'sm:top-14' : 'mt-2'
})

const mtHubChatButton = computed(() => {
  // Отступ кнопки открытия/закрытия чата в hub'е с такими настройками: h-10 (2.5rem) + mb-2.5 (0.625rem) + mt-3 (0.75rem)
  // calc(2.5rem + 0.625rem + 0.75rem) = 3.875rem
  return props.isInIframe ? '3.875rem' : ''
})

onMounted(async () => {
  const { gsap } = await import('gsap')
  const mapUrl = props.formData.image
  // @ts-ignore-next-line
  const response: Blob = await $fetch(mapUrl)

  loading.value = false

  const responseText = await response?.text?.()
  if (!responseText) {
    useLogError(new Error(`Failed to fetch svg as text from file ${mapUrl}`))
    return
  }
  const svgData = renameCSSClasses(responseText)

  if (expoBlock.value && map.value && svgData) {
    map.value.innerHTML = svgData
    const svgElement = map?.value?.querySelector('svg')
    if (svgElement) {
      svgElement.style.width = '100%'
      svgElement.style.height = '100%'
      svgElement.style.objectFit = 'contain'
      svgElement.style.transition = 'transform 0.2s'
    }

    initConstValues()
    centerExpo()

    window.addEventListener('resize', resizeHandler)
    resizeFunction.value = resizeHandler

    expoBlock.value?.addEventListener('touchstart', clickBlockStart)
    expoBlock.value?.addEventListener('mousedown', clickBlockStart)

    expoBlock.value?.addEventListener('touchmove', handleMovement)
    expoBlock.value?.addEventListener('mousemove', handleMovement)

    expoBlock.value?.addEventListener('touchend', clickBlockEnd)
    expoBlock.value?.addEventListener('mouseup', clickBlockEnd)

    expoBlock.value?.addEventListener('mouseleave', clickBlockEnd)
    expoBlock.value?.addEventListener('touchleave', clickBlockEnd)

    guiPlus.value?.addEventListener('mousedown', handleZoomPlus)
    guiMinus.value?.addEventListener('mousedown', handleZoomMinus)
  }

  function resizeHandler(event: any) {
    if (event.type === 'resize') {
      initConstValues()
      centerExpo()
    }
  }

  function clickBlockStart(e: Event) {
    const isButtonClicked = e?.target instanceof HTMLButtonElement
    if (isButtonClicked) {
      return
    }

    const eventClickOne = (e as TouchEvent).touches?.[0] ?? (e as MouseEvent)
    const eventClickTwo = (e as TouchEvent).touches?.[1]
    startX.value = eventClickOne.clientX - offsetX.value
    startY.value = eventClickOne.clientY - offsetY.value
    isDragging.value = true
    // isZoomByTouch.value = !!eventClickTwo
    if (eventClickTwo) {
      dY.value = eventClickTwo.clientY - eventClickOne.clientY
    }
  }

  function setOffsetsByBorders(x: number, y: number) {
    if (!expoBlock.value || !map.value) {
      return
    }
    const styleExpoElement = window.getComputedStyle(expoBlock.value)
    const styleMapContainer = window.getComputedStyle(map.value)
    const blockHeight = parseInt(styleExpoElement.getPropertyValue('height'))
    const mapHeight = parseInt(styleMapContainer.getPropertyValue('height'))
    const blockWidth = parseInt(styleExpoElement.getPropertyValue('width'))
    const mapWidth = parseInt(styleMapContainer.getPropertyValue('width'))
    const mapScale = parseFloat(gsap.getProperty(map.value, 'scale').toString())
    const dWidth = mapWidth * mapScale - blockWidth
    const dHeight = mapHeight * mapScale - blockHeight
    if (y > 0) {
      y = 0
    }
    const newY = dHeight + y
    if (newY < 0) {
      y = -dHeight
    }
    if (x > 0) {
      x = 0
    }
    const newX = dWidth + x
    if (newX < 0) {
      x = -dWidth
    }
    if (x !== offsetX.value) {
      offsetX.value = x
      gsap.set(map.value, { x: offsetX.value })
    }
    if (y !== offsetY.value) {
      offsetY.value = y
      gsap.set(map.value, { y: offsetY.value })
    }
  }

  function handleMovement(e: Event) {
    if (!map.value) {
      return
    }

    if (isDragging.value) {
      e.preventDefault()
      const eventClick = (e as TouchEvent).touches?.[0] ?? (e as MouseEvent)
      setOffsetsByBorders(
        eventClick.clientX - startX.value,
        eventClick.clientY - startY.value
      )
      gsap.killTweensOf(map.value)
      gsap.to(map.value, {
        duration: 0.2,
        x: offsetX.value,
        y: offsetY.value,
        ease: 'power2.out',
      })
    }

    if (isZoomByTouch.value) {
      const touchDScale = dY.value * dScaleZooming.value
      const scaleTouch = parseFloat(
        gsap.getProperty(map.value, 'scale').toString()
      )
      const newScale = maxScale.value - touchDScale
      if (scaleTouch <= newScale) {
        scale.value = scaleTouch + touchDScale
        gsap.killTweensOf(map.value)
        gsap.to(map.value, {
          duration: 1,
          scale: scaleTouch + touchDScale,
          transformOrigin: '0% 0%',
          ease: 'power2.out',
          onUpdate: () => {
            if (map.value) {
              offsetX.value = parseInt(
                gsap.getProperty(map.value, 'x').toString()
              )
              offsetY.value = parseInt(
                gsap.getProperty(map.value, 'y').toString()
              )
            }
            setOffsetsByBorders(offsetX.value, offsetY.value)
          },
        })
      }
    }
  }

  function clickBlockEnd() {
    isDragging.value = false
    isZoomByTouch.value = false
  }

  function zoom() {
    if (!map.value) {
      return
    }

    const styleMapContainer = window.getComputedStyle(map.value as Element)
    const mapScale = parseFloat(gsap.getProperty(map.value, 'scale').toString())
    const mapWidth =
      parseInt(styleMapContainer.getPropertyValue('width')) * mapScale
    const mapHeight =
      parseInt(styleMapContainer.getPropertyValue('height')) * mapScale
    const mapWidthScaled =
      parseInt(styleMapContainer.getPropertyValue('width')) * scale.value
    const mapHeightScaled =
      parseInt(styleMapContainer.getPropertyValue('height')) * scale.value
    const dWidth = mapWidthScaled - mapWidth
    const dHeight = mapHeightScaled - mapHeight
    const newOffsetX = offsetX.value - dWidth / 2
    const newOffsetY = offsetY.value - dHeight / 2

    gsap.killTweensOf(map.value)
    gsap.to(map.value, {
      duration: 1,
      x: newOffsetX,
      y: newOffsetY,
      scale: scale.value,
      transformOrigin: '0% 0%',
      ease: 'power2.out',
      onUpdate: () => {
        if (map.value) {
          offsetX.value = parseInt(gsap.getProperty(map.value!, 'x').toString())
          offsetY.value = parseInt(gsap.getProperty(map.value!, 'y').toString())
        }
        setOffsetsByBorders(offsetX.value, offsetY.value)
      },
    })
  }

  function disableElement(element: HTMLElement) {
    element?.classList?.add('disabled')
  }

  function enableElement(element: HTMLElement) {
    element?.classList?.remove('disabled')
  }

  function handleZoomPlus() {
    if (!map.value) {
      return
    }
    enableElement(guiMinus.value as HTMLButtonElement)

    scale.value += dScale.value
    if (scale.value >= maxScale.value) {
      scale.value = maxScale.value
      disableElement(guiPlus.value as HTMLButtonElement)
    } else {
      enableElement(guiPlus.value as HTMLButtonElement)
    }
    zoom()
  }

  function handleZoomMinus() {
    if (!map.value) {
      return
    }
    enableElement(guiPlus.value as HTMLButtonElement)
    scale.value -= dScale.value
    if (scale.value <= minScale.value) {
      scale.value = minScale.value
      disableElement(guiMinus.value as HTMLButtonElement)
    } else {
      enableElement(guiMinus.value as HTMLButtonElement)
    }
    zoom()
  }

  function renameCSSClasses(svgData: string): string {
    let newSvgData: string = svgData
    const stylesRegex = /<style>([\s\S]*?)<\/style>/gi
    // Находим блок со стилями <style> ... </style>
    const styles = svgData.match(stylesRegex)
    if (Array.isArray(styles)) {
      const classesRegex = /([^\S}>])\.([\w-]+)[\s{,]/gi
      for (const style of styles) {
        // Вибираем из него все имена классов
        const classes = style.match(classesRegex)
        if (Array.isArray(classes) && classes.length > 0) {
          // Убираем повторяющиеся имена классов
          const uniqueClasses = Array.from(new Set(classes))
          for (const className of uniqueClasses) {
            const regex = new RegExp(`\\b${className.substring(1)}\\b`, 'g')
            // заменяем во всём svgData все упоминания классов из uniqueClasses
            newSvgData = newSvgData.replace(
              regex,
              `expo-${props.uuid}-${className.substring(1)}`
            )
          }
        }
      }
    }
    return newSvgData
  }

  function initConstValues() {
    offsetX.value = 0
    offsetY.value = 0
    startX.value = 0
    startY.value = 0
    dY.value = 0
    isDragging.value = false
    isZoomByTouch.value = false
    minScale.value = 1
    maxScale.value = 2
    dScale.value = 0.25
  }

  function centerExpo() {
    if (!map.value || !expoBlock.value) {
      return
    }

    const styleExpoElement = window.getComputedStyle(expoBlock.value)
    const styleMapContainer = window.getComputedStyle(map.value)
    const blockHeight = parseInt(styleExpoElement.getPropertyValue('height'))
    const mapHeight = parseInt(styleMapContainer.getPropertyValue('height'))
    const blockWidth = parseInt(styleExpoElement.getPropertyValue('width'))
    const mapWidth = parseInt(styleMapContainer.getPropertyValue('width'))

    if (blockHeight > mapHeight) {
      minScale.value = blockHeight / mapHeight
      offsetX.value = -(mapWidth * minScale.value - blockWidth) / 2
    } else {
      minScale.value = blockWidth / mapWidth
      offsetY.value = -(mapHeight * minScale.value - blockHeight) / 2
    }
    maxScale.value *= minScale.value
    dScale.value = (maxScale.value - minScale.value) / 4
    disableElement(guiMinus.value as HTMLButtonElement)
    enableElement(guiPlus.value as HTMLButtonElement)

    scale.value = minScale.value
    gsap.set(map.value, {
      x: offsetX.value,
      y: offsetY.value,
      scale: scale.value,
      transformOrigin: '0% 0%',
    })
  }
})
</script>
<style lang="css" scoped>
.map-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}

.gui-minus.disabled,
.gui-plus.disabled {
  filter: grayscale(100%);
  opacity: 0.7;
  cursor: not-allowed;
  pointer-events: none;
}
</style>
