<template>
  <div
    :id="uuid"
    class="px-6 py-3 xl:px-0"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
    }"
  >
    <h2
      v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
      class="text-2xl md:text-3xl font-bold mb-4 md:mb-6"
      :style="{ textAlign: formData.titleAlign }"
    >
      {{ formData.title }}
    </h2>
    <div
      class="grid grid-cols-2 xxs:grid-cols-3 md:grid-cols-[repeat(auto-fit,_minmax(50px,_1fr))] gap-3 lg:gap-3"
    >
      <template v-for="(tile, index) in formData.tiles" :key="'grid' + index">
        <TilesCard
          :link="tile.link"
          :is-blank="tile.isBlank"
          :image-url="tile.imageUrl"
          :title="tile.title"
          :shadow="formData.blockShadow"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import TilesCard from './TilesCard.vue'
import { usePageEditor } from '~/stores/page-editor'

interface PropsInterface {
  uuid: string
  formData: any
  isInIframe?: boolean
}

defineProps<PropsInterface>()

const pageEditorStore = usePageEditor()
</script>
