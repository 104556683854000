import { markRaw } from 'vue'
import { DEFAULT_IMAGE_SVG_SQUARE, DEFAULT_LONG_TEXT } from '../constants'
import AccordionView from './AccordionView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(AccordionView),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    title: 'How we work',
    image: DEFAULT_IMAGE_SVG_SQUARE,
    maxWidth: 576, // max-w-xl
    imageTopPadding: '0rem',
    align: 'w-full',
    numeration: false,
    closeOther: true,
    tabs: [
      {
        label: 'Suspendisse elementum purus',
        text: DEFAULT_LONG_TEXT,
      },
      {
        label: 'Sed tincidunt tellus eu neque dapibus',
        text: DEFAULT_LONG_TEXT,
      },
      {
        label: 'Fusce eget metus quis erat',
        text: DEFAULT_LONG_TEXT,
      },
      {
        label: 'Integer a libero vel quam',
        text: DEFAULT_LONG_TEXT,
      },
      {
        label: 'Suspendisse in urna blandit',
        text: DEFAULT_LONG_TEXT,
      },
    ],
  },
}
