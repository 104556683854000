<template>
  <div
    :id="uuid"
    class="p-6 md:p-8 bg-white rounded-xl"
    :class="formData.blockShadow && 'shadow-lg'"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      color: formData.textColor,
      'background-color': formData.bgColor,
    }"
  >
    <h2
      v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
      class="text-2xl md:text-3xl font-bold mb-2 md:mb-6"
      :style="{ textAlign: formData.titleAlign }"
    >
      {{ formData.title }}
    </h2>
    <div
      class="flex gap-6"
      :class="
        formData.imagePosition === 'left'
          ? 'flex-col-reverse md:flex-row-reverse'
          : 'flex-col md:flex-row'
      "
    >
      <div
        class="flex flex-col gap-4"
        :class="formData.image ? 'flex-1' : 'w-full max-w-3xl mx-auto'"
      >
        <div
          class="marked"
          v-html="
            getMarkedTextWithBlankLinks(formData.text, formData.linkColor)
          "
        />
        <div
          v-if="formData.statCards?.length"
          class="grid gap-4 mt-auto pt-2"
          :class="gridColsClass"
        >
          <template v-for="(card, index) in formData.statCards" :key="index">
            <div
              class="rounded-xl px-3 py-5"
              :class="
                formData.statCards.length % 2 === 1 &&
                index === formData.statCards.length - 1 &&
                'max-md:col-span-2'
              "
              :style="{
                color: formData.cardTextColor,
                backgroundColor: formData.cardBgColor,
              }"
            >
              <div
                class="text-4xl md:text-[46px] break-normal uppercase font-extralight mb-2"
              >
                {{ card.title }}
              </div>
              <div
                class="text-sm md:text-base leading-4 md:leading-5 font-light"
              >
                {{ card.subtitle }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        v-if="formData.image"
        class="max-md:w-full shrink-0"
        :class="formData.imageSize"
      >
        <img :src="formData.image" class="w-full rounded-xl overflow-hidden" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePageEditor } from '~/stores/page-editor'
import { getGridColsClass } from '~/components/base/utils'

const pageEditorStore = usePageEditor()

interface PropsInterface {
  uuid: string
  formData: any
  isInIframe?: boolean
}

const props = defineProps<PropsInterface>()

const { getMarkedTextWithBlankLinks } = useMarkedText()

const gridColsClass = computed(() => {
  return getGridColsClass(
    props.formData.colsNumberOnDesktop,
    props.formData.colsNumberOnMobile
  )
})
</script>
