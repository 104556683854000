<template>
  <div
    ref="target"
    :class="[!event.slug && 'opacity-50 filter grayscale pointer-events-none']"
    class="flex flex-col gap-3"
  >
    <BaseLocaleLink
      class="relative"
      :to="route"
      :class="!event.slug && 'pointer-events-none'"
    >
      <BaseRatio
        :ratio="1 / 2"
        size="cover"
        class="rounded-xl overflow-hidden hover:opacity-90 transition duration-150 ease-in-out bg-gray-200"
        :alt="event.name"
      >
        <div class="w-full h-full flex items-center justify-center">
          <img
            loading="lazy"
            :src="logo || '/event-default-logo-1200x630.png'"
            class="w-full"
          />
        </div>
      </BaseRatio>
      <div
        v-if="event.is_has_ticket"
        class="absolute top-2 right-2 w-10 h-10 bg-white text-primary rounded-xl flex items-center justify-center"
      >
        <BaseIcon size="sm" name="outline_ticket" />
      </div>
    </BaseLocaleLink>
  </div>
</template>

<script setup lang="ts">
import type { EventLite } from '~/models/events'
import { useEvents } from '~/stores/events'

const { getEventPageRoute } = useEvents()
const { getLocalMedia } = useLocalMedia()

interface PropsInterface {
  event: EventLite
  favoriteInitialValue?: boolean
}

const props = withDefaults(defineProps<PropsInterface>(), {
  favoriteInitialValue: undefined,
})

const route = getEventPageRoute(props.event.slug, props.event.type)

const logo = computed(() => getLocalMedia(props.event.logo)?.url)
</script>
