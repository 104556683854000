import { Capacitor } from '@capacitor/core'
export enum Device {
  Windows = 'windows',
  WindowsPhone = 'windows_phone',
  Android = 'android',
  IOS = 'ios',
  Unknown = 'unknown',
}

const webviewRegexpRules = [
  // if it says it's a webview, let's go with that
  'WebView',
  // iOS webview will be the same as safari but missing "Safari"
  '(iPhone|iPod|iPad)(?!.*Safari)',
  // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
  // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
  'Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})',
  // old chrome android webview agent
  'Linux; U; Android',
]

const webviewRegExp = new RegExp('(' + webviewRegexpRules.join('|') + ')', 'ig')

export const useDevice = () => {
  const userAgent =
    (process.server
      ? useRequestHeaders()['user-agent']
      : navigator.userAgent || navigator.vendor) || ''

  function getPlatform() {
    if (/Windows NT/i.test(userAgent)) {
      return Device.Windows
    }

    if (/windows phone/i.test(userAgent)) {
      return Device.WindowsPhone
    }

    if (/android/i.test(userAgent)) {
      return Device.Android
    }

    if (
      /iPad|iPhone|iPod/.test(userAgent) &&
      // @ts-ignore-next-line
      (process.client ? !window.MSStream : true)
    ) {
      return Device.IOS
    }

    return Device.Unknown
  }

  let platform = getPlatform() as string

  try {
    platform = Capacitor.isNativePlatform()
      ? Capacitor.getPlatform()
      : getPlatform()
  } catch (error: any) {
    useLogError(error)
  }

  const isMobile = platform === Device.IOS || platform === Device.Android
  return {
    isMobile,
    platform,
    isIOS: platform === Device.IOS,
    isAndroid: platform === Device.Android,
    isWebview: !!userAgent.match(webviewRegExp),
  }
}
