import {
  DEFAULT_COLOR_GRAY,
  DEFAULT_IMAGE_SQUARE,
  DEFAULT_LINK,
  DEFAULT_MARKDOWN_PLACEHOLDER,
} from '../constants'
import { basePropertiesSchema } from '../base-schema-properties'
import {
  buttonsPropertiesSchema,
  titlePropertiesSchema,
  shadowSchema,
  bgColorSchema,
  linkColorSchema,
  showAllSettingsSchema,
  textColorSchema,
  colsNumberOnDesktopSchema,
  colsNumberOnMobileSchema,
  isUseSliderOnMobileSchema,
} from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Content (with linkable images)',
  description: 'Two-column with text, images(links) and button(link)',
  type: 'object',
  required: ['text', 'colsNumberOnDesktop', 'colsNumberOnMobile'],
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    tmp_showAllSettings: ['textColor', 'bgColor', 'linkColor', 'blockShadow'],
    title: ['titleAlign', 'hideOnEmbed'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    ...showAllSettingsSchema,
    ...textColorSchema,
    ...linkColorSchema,
    ...bgColorSchema,
    ...shadowSchema,
    text: {
      title: 'Text',
      type: 'string',
      'ui:options': {
        placeholder: DEFAULT_MARKDOWN_PLACEHOLDER,
        type: 'textarea',
        rows: 6,
      },
    },
    textColor: {
      title: 'Text Color',
      type: 'string',
      default: DEFAULT_COLOR_GRAY,
    },
    ...colsNumberOnDesktopSchema,
    ...colsNumberOnMobileSchema,
    ...isUseSliderOnMobileSchema,
    isOpenFullscreen: {
      title: 'Open images in fullscreen gallery',
      type: 'boolean',
      default: false,
    },
    images: {
      type: 'array',
      title: 'A list of images',
      'ui:options': {
        showIndexNumber: true,
      },
      items: {
        title: 'Image',
        type: 'object',
        properties: {
          image: {
            title: 'Image url',
            type: 'string',
            default: DEFAULT_IMAGE_SQUARE,
          },
          link: {
            title: 'Image link',
            type: 'string',
            default: DEFAULT_LINK,
          },
        },
      },
    },
    ...buttonsPropertiesSchema,
  },
}
